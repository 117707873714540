<template>
    <div>
        <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
            <template #title>
                题库答疑
            </template>
        </van-nav-bar>
        <div style="padding: 6rem 0 22rem 0;">
            <van-tabs v-model:active="active" class="questionList" color="#1989fa" swipeable @click="clickTab" >
                <van-tab name="0" title="已回复">
                    <div style="padding-top: 0.5rem;" v-if="repliedAllList.length>0">
                        <div class="courseList1" @click="questionDetail(item.id)" v-for="(item,index) in repliedAllList" :key="index" >
                            <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">{{item.cate_name ? item.cate_name : '无'}}</van-tag>
                            <van-cell center class="answering">
                                <template #icon>
                                    <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"/>
                                </template>
                                <template #title>
                                    <span class="custom-title">{{item.username ? item.username : '无'}}</span><br>
                                    <van-tag color="#f8f8f8" text-color="#969799" style="font-seze:1.6rem">{{item.create_time ? item.create_time : '无'}} - 来自{{item.source}}</van-tag>                                    <van-tag color="#f8f8f8" text-color="#969799">{{item.create_time ? item.create_time : '无'}} - 来自{{item.source}}</van-tag><br>

                                    <span style="color: #646566;font-size: 1.8rem;">试题：</span>
                                    <span style="color: #646566;font-size: 1.8rem;" v-html="item.questionTitle"></span><br>
                                </template>
                                <template #label>
                                    <span>{{item.title}}</span>
                                </template>
                            </van-cell>
                            <div class="reply_tag" v-if="item.children">
                                <van-tag round color="#1989fa" text-color="#ffffff">答</van-tag>
                                <span class="reply_text">{{item.children ? '亲爱的同学，很高兴能为您提供帮助，'+ item.children.title : '无'}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else><van-empty image-size="30rem" description="暂无答疑问题"/></div>
                </van-tab>
                <van-tab name="1" title="未回复">
                    <div style="padding-top: 0.5rem;" v-if="repliedAllList.length>0">
                        <div class="courseList1" @click="questionDetail(item.id)" v-for="(item,index) in repliedAllList" :key="index">
                            <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">{{item.cate_name ? item.cate_name : '无'}}</van-tag>
                            <van-cell center class="answering">
                                <template #icon>
                                    <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"/>
                                </template>
                                <template #title>
                                    <span class="custom-title">{{item.username ? item.username : '无'}}</span><br>
                                    <van-tag color="#f8f8f8" text-color="#969799"  style="font-seze:1.6rem">{{item.create_time ? item.create_time : '无'}} - 来自{{item.source}}</van-tag><br>
                                    <span  style="color: #646566;font-size: 1.8rem;">试题：</span><span style="color:#656466;font-size: 1.8rem;" v-html="item.questionTitle"></span><br>
                                </template>
                                <template #label>
                                    <span>{{item.title}}</span>
                                </template>
                            </van-cell>
                        </div>
                    </div>
                    <div v-else><van-empty image-size="30rem" description="暂无答疑问题"/></div>

                </van-tab>
            </van-tabs>


        </div>
    </div>
</template>

<script>
    let formName;
    // import Tips from '@/components/Tips'
    import {questionAnswerList} from '@/request/api'
    import {Toast, Loading} from "vant";
    export default {
        beforeRouteEnter(to, from, next) {
            let that = this;
            // console.log(to);
            // console.log(from);
            formName = from.name;
            next();
        },
        name: 'QuestionAnswer',
        metaInfo: {
            title: '题库答疑', //设置页面title
            meta: [{                 //设置页面meta
                name: 'keyWords',
                content: '关键词'
            },
                {
                    name: "description",
                    content: "描述",
                },
            ]
        },
        data() {
            return {
                // active:'',
                repliedAllList: [],
                type:0,
                appOrH5:false
            }
        },
        created() {
            this.getQuestionAnswerList();
            this.appOrH5 = this.$route.query.appOrH5;
        },

        methods: {
            questionDetail(id) {
                this.$router.push({
                    name:'questionAnswerDetail',
                    params:{
                        id
                    },
                    query:{
                        id
                    }
                });
            },

            onBack() {
                let url = 'Question';
                if(!this.isWeiXin()){
                  if(window.android != undefined) {
                    if(window.android.isapp() == true) {
                      url = 'QuestionH5';
                    }
                  }
                }

                this.$router.push({
                    name:url,
                    params:{

                    },
                    query:{

                    }
                });
            },
            getQuestionAnswerList() {
                let that = this;
                let type = that.type;
                questionAnswerList({type}).then(res => {
                    if (res.data.status == 1) {
                        that.repliedAllList = res.data.data
                    } else {
                        Toast.fail(res.data.msg)
                    }
                })
            },
            clickTab(name,title) {
                this.type = name
                this.getQuestionAnswerList();
            },
        },
    }
</script>

<style lang="less">
    .questionList .courseList1 {
        margin: 0.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
        padding-bottom: 1.5rem;
    }

    .questionList .courseList1 .van-cell {
        border-radius: 1rem !important;
        padding: 1rem 1.5rem;
    }

    .questionList .courseList1 .van-cell::after {
        border-width: 0 !important;
    }

    .questionList .courseList1 .answering .custom-title {
        font-size: 1.8rem;
        padding-right: 1rem;
    }

    .questionList .courseList1 .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }

    .questionList .courseList1 .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 3.4rem;
    }

    .questionList .courseList1 .project_tag {
        position: absolute;
        right: 1rem;
        float: right;
        padding: 0.3rem 0.5rem !important;
        font-size: 1.4rem !important;
        z-index: 99999;
        border-top-right-radius: 1rem;
    }

    .questionList .courseList1 .reply_tag {
        background-color: #f0f0f0;
        border-radius: 3rem;
        padding-left: 0.3rem;
        height: 3.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
        margin: 0 auto;
    }

    .questionList .courseList1 .reply_tag .van-tag {
        height: 2.8rem !important;
        // width: 3rem !important;
        border-radius: 3rem;
        padding-left: 0.5rem;
        font-size: 1.8rem !important;
    }

    .questionList .courseList1 .reply_tag .reply_text {
        padding-left: 0.2rem;
        font-size: 1.8rem;
        color: #646566;
    }

</style>