<template>
    <div class="second">
        
 
        <!-- 问卷题 -->
        <div v-for="(item,index) in questionList" :key="item.id">    
            <!-- 测试 -->
            <div class="test-content" v-if="index== page">
                <div class="test-title" v-if="item.mqyid == 5">平和体质--健康派</div>
                <div class="test-title" v-if="item.mqyid == 8">气虚体质--气短派</div>
                <div class="test-title" v-if="item.mqyid == 9">阳虚体质--怕冷派</div>
                <div class="test-title" v-if="item.mqyid == 10">痰湿体质--痰派</div>
                <div class="test-title" v-if="item.mqyid == 11">湿热体质--长痘派</div>
                <div class="test-title" v-if="item.mqyid == 12">血瘀体质--长斑派</div>
                <div class="test-title" v-if="item.mqyid == 13">特禀体质---过敏派</div>
                <div class="test-title" v-if="item.mqyid == 14">气郁体质---郁闷派</div>
                <div class="test-title" v-if="item.mqyid == 15">阴虚体质---缺水派</div>
                
                <div class="content-title">{{index+1}}.{{item.question}}</div>
 
                <van-radio-group  v-model="radio">
                    <van-radio :name="item.answer1Num  + ',' + item.id">{{item.answer1}}</van-radio>
                    <van-radio :name="item.answer2Num  + ',' + item.id">{{item.answer2}}</van-radio>
                    <van-radio :name="item.answer3Num  + ',' + item.id">{{item.answer3}}</van-radio>
                    <van-radio :name="item.answer4Num  + ',' + item.id">{{item.answer4}}</van-radio>
                    <van-radio :name="item.answer5Num  + ',' + item.id">{{item.answer5}}</van-radio>
                </van-radio-group>
 
                <div class="btn">
                    <div class="back" @click="toBack(index)" v-if="index != 0" :class="index == 0 ? 'before-back' : 'back' ">上一题</div>
                    <div class="next" @click="toNext(index,item.id)" v-if="isShow"  :class="index == 0 ? 'next' : 'before-next'">下一题</div>
 
                    <!-- 提交 -->
                    <div class="next" v-if="!isShow" @click="submit(item)" :class="index == 0 ? 'next' : 'before-next'">提交</div>
                    
                </div>
            </div>
            
        </div>
 
 
    </div>
</template>
 
export default {<template>
    <div class="second">
        
 
        <!-- 问卷题 -->
        <div v-for="(item,index) in questionList" :key="item.id">    
            <!-- 测试 -->
            <div class="test-content" v-if="index== page">
                <div class="test-title" v-if="item.mqyid == 5">平和体质--健康派</div>
                <div class="test-title" v-if="item.mqyid == 8">气虚体质--气短派</div>
                <div class="test-title" v-if="item.mqyid == 9">阳虚体质--怕冷派</div>
                <div class="test-title" v-if="item.mqyid == 10">痰湿体质--痰派</div>
                <div class="test-title" v-if="item.mqyid == 11">湿热体质--长痘派</div>
                <div class="test-title" v-if="item.mqyid == 12">血瘀体质--长斑派</div>
                <div class="test-title" v-if="item.mqyid == 13">特禀体质---过敏派</div>
                <div class="test-title" v-if="item.mqyid == 14">气郁体质---郁闷派</div>
                <div class="test-title" v-if="item.mqyid == 15">阴虚体质---缺水派</div>
                
                <div class="content-title">{{index+1}}.{{item.question}}</div>
 
                <van-radio-group  v-model="radio">
                    <van-radio :name="item.answer1Num  + ',' + item.id">{{item.answer1}}</van-radio>
                    <van-radio :name="item.answer2Num  + ',' + item.id">{{item.answer2}}</van-radio>
                    <van-radio :name="item.answer3Num  + ',' + item.id">{{item.answer3}}</van-radio>
                    <van-radio :name="item.answer4Num  + ',' + item.id">{{item.answer4}}</van-radio>
                    <van-radio :name="item.answer5Num  + ',' + item.id">{{item.answer5}}</van-radio>
                </van-radio-group>
 
                <div class="btn">
                    <div class="back" @click="toBack(index)" v-if="index != 0" :class="index == 0 ? 'before-back' : 'back' ">上一题</div>
                    <div class="next" @click="toNext(index,item.id)" v-if="isShow"  :class="index == 0 ? 'next' : 'before-next'">下一题</div>
 
                    <!-- 提交 -->
                    <div class="next" v-if="!isShow" @click="submit(item)" :class="index == 0 ? 'next' : 'before-next'">提交</div>
                    
                </div>
            </div>
            
        </div>
 
 
    </div>
</template>
<script>
export default {
    name: 'SecondQuestionnaire',
 
    data() {
        return {
            // 当前页
            page: 0,
            // 选项
            radio: '',
            // 题目的id
            ids: [],
            // 分数
            nums: [],
            // 性别
            gender: '',
            // 年龄
            age: '',
            // 存放每一题的id和num
            questionInfos: [],
            // 当前题目的序号等于当前题目的条数
            isShow: true,
            // 获取问卷数据
            questionList: []
        }
    },
    created() {
        // 接受上一层传来的数据
        this.gender = this.$route.params.gender
        this.age = this.$route.params.age
 
         // 获取问卷数据
        this.getQuestion()
    },
    methods: {
        // 获取问卷数据
        async getQuestion() {
            const data = await this.$http.get('/questionList?openId=123123')
 
            if(data.status !== 200) {
                return this.$Toast.fail('获取调查问卷题失败')
            }
 
            this.questionList = data.data.question1          
 
        },
        
        // 点击下一题
        toNext(index, id) {
            // 1. 判断是否答题，没有则无法跳转到下一题
            if(index === 0) {
                if((this.radio.slice(0,1) === '' || this.radio.slice(0,1) === undefined)) {
                    return;
                }
            } else {
                if(this.radio.slice(2) != id) {
                    return;
                }
            }
            
            // 2. 保存答题数据
            let question = {'ids': this.radio.slice(2), 'nums': this.radio.slice(0, 1),};
            this.questionInfos[index] = question;
            this.questionInfos.forEach(questionInfo => {
                // console.log('questionInfo:[ids: ' + questionInfo.ids + ', nums: ' + questionInfo.nums + '] ');
 
            });
            // 3. index ++；
            index ++;
            // 4. 判断已答题目列表是否包含下标为index的题目，有则回显
            if(this.questionInfos[index] !== undefined && this.questionInfos[index] !== '') {
                this.radio = this.questionInfos[index].nums + ',' + this.questionInfos[index].ids;
               
            }
            // 5. page ++
            this.page ++;
            // 6. 其他（如按钮隐藏）
           // 当前页等于最后一题 下一题按钮隐藏
            if(this.page == this.questionList.length-1) {
                return this.isShow = false
            } else {
                return this.isShow = true
            }
 
        },
 
        // 点击上一题
        toBack(index) {
            // 1. index --；
            index --;
            // 2. 从已回答列表中获取答题数据，回显
            this.radio = this.questionInfos[index].nums + ',' + this.questionInfos[index].ids;
            // 3. 保存答题数据
            let question = {'ids': this.radio.slice(2), 'nums': this.radio.slice(0, 1)};
            this.questionInfos[index] = question;
 
            // 4. page --
            this.page --;
     
        },
 
 
        // 点击提交
        async submit(n) {
     
             for(var i = 0; i < this.questionInfos.length; i++) {
               
                this.ids[i] = this.questionInfos[i].ids
                
                this.nums[i] = this.questionInfos[i].nums
               
            }
            // 发现点击最后一题时用户点击提交 打印出来少一条最后一页的数据  
            // 用户点击提交 手动将最后一条数据添加到数组中
            
            this.ids.push(this.radio.slice(2))
            this.nums.push(this.radio.slice(0,1))
 
 
            const data = await this.$http.post(`saveQuestions?openId=123123&ids=${this.ids}&nums=${this.nums}&age=${this.age}&gender=${this.gender}`)
 
            if(data.data.code == 200) {
                this.$router.push({
                    name: 'Muggy',
                    params: {
                        typeTitle: data.data.mobuserQuestionnaireType.typeTitle,
                        performance: data.data.mobuserQuestionnaireType.performance,
                        describetion: data.data.mobuserQuestionnaireType.describetion,
                        method: data.data.mobuserQuestionnaireType.method
                    }
                })
            }
 
 
        },
    }
}
</script> 