<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        题目收藏
      </template>
    </van-nav-bar>
    <div style="padding: 6rem 0 22rem 0;">
      <van-tabs v-model:active="active" class="testList" color="#1989fa" swipeable sticky @click="clickTab">
          <van-tab :title="itemS.abbreviation_name" :name="itemS.id" v-for="(itemS, indexS) in subjectList" :key="indexS">
              <div style="padding-top: 0.5rem;" v-if="questionList.length>0">
                    <div class="testData" v-for="(item,index) in questionList" :key="index" >
                        <van-cell center class="answering">
                            <template #title>
                                <div class="testType" >
                                  <span v-if="item.type==2">
                                    单选题
                                  </span>
                                  <span v-else-if="item.type==3">
                                    多选题
                                  </span>
                                  <span v-else-if="item.type==5">
                                    综合分析题
                                  </span>
                                  <span v-else-if="item.type==6">
                                    配伍选择题
                                  </span>
                                  <span v-else-if="item.type==17">
                                    判断题
                                  </span>
                                  <span v-else>
                                    简答题
                                  </span>
                                </div>
                                <span class="addtime">
                                  <span @click="collection(item.id,2)"><van-icon name="star" color="#ffd21e" size="2.2rem" style="margin:0 0.5rem;padding-right: 0rem;" /></span>
                                  <span style="margin:0 0.5rem;color:#CCCCCC">|</span>
                                  {{item.addtime}}
                                </span>
                                <!-- 题干 -->
                                <div class="isStem" v-if="item.type==5 || item.type==6">
                                  <span data-v-0b378ed5="" class="data_value" v-html="item.data_title"></span>
                                  <van-image
                                    v-for="(item2,index) in item.title_img" :key="index"
                                    width="16rem"
                                    height="10rem"
                                    fit="fill"
                                    :src="item2"
                                    @click="getImg(item.title_img, index)"
                                  />
                                  <span v-for="(item3,index) in item.title_video" :key="index">
                                    <video
                                            id="myVideo"
                                            class="video-js"
                                            controls
                                            preload="auto"
                                            poster="https://video.ksyt.com.cn/8bec156084b771ed80e86732b78e0102/snapshots/73d4caec7dc34dcd9c88eb4716715f25-00005.jpg"
                                            :style="contentStyleObj"
                                    >
                                      <source :src="item3" type="video/mp4">
                                    </video>
                                  </span>
                                </div>
                                <div class="custom-title" v-html="item.title"></div>
                            </template>
                            <template #label>
                              <div class="option" v-for="(itemI,indexI) in item.tmList" :key="indexI">
                                <p>
                                  <span class="option_key">{{itemI.key}}.</span>
                                  <span class="option_value">{{itemI.value}}
                                      <div v-if="itemI.tmImg.length > 0">
                                            <van-image v-for="(itemI2,indexI2) in itemI.tmImg" :key="indexI2" class="title_img" fit="fill" :src="itemI2"/>
                                      </div>
                                  </span>
                                </p>
                              </div>
                              <div data-v-0a1ab55d="" class="comRadio_answer">
                                  <div data-v-0a1ab55d="" class="answer_box">
                                    <span data-v-0a1ab55d="" class="my_answer">参考答案：{{item.correct_answer}}</span>
    <!--                                <span data-v-0a1ab55d="" class="reference_answer_correct">我的答案：{{item.answer}}</span>-->
                                  </div>
                                  <div data-v-0a1ab55d="" class="knowledge_box">
                                    <span data-v-0a1ab55d="" class="knowledge">考察知识点：</span>
                                    <span data-v-0a1ab55d="" class="knowledge-text">{{item.knowledge}}</span>
                                  </div>
                                  <div data-v-0a1ab55d="" class="accuracy_box">
                                    <span data-v-0a1ab55d="" class="accuracy">全网正确率：</span>
                                    <span data-v-0a1ab55d="" class="accuracy-text">{{item.accuracy}}</span>
                                  </div>
                              </div>

                              <div class="comRadio_video_analysis" v-if="item.videoAnalysis != ''">
                                  <van-cell center title="讲师视频讲解" style="padding: 0">
                                  </van-cell>
                                  <div class="video_box" ref="attrRef">
                                      <video
                                              id="myVideo"
                                              class="video-js"
                                              controls
                                              preload="auto"
                                              :poster="item.videoAnalysisPoster"
                                              :style="contentStyleObj"
                                      >
                                          <source :src="item.videoAnalysis" type="video/mp4">
                                      </video>
                                  </div>
                              </div>
                              <div class="comRadio_analysis">
                                  <van-cell center use-label-slot title="解析" style="padding: 0">
                                      <!-- <template #right-icon>
                                          <van-button round center type="warning" size="mini" icon="question-o" @click="onShowShare">我要提问</van-button>
                                      </template> -->
                                  </van-cell>
                                  <van-tag color="#f6f7f9" text-color="#646566" size="large">
                                    <!-- <span v-html="obj.analysis"></span> -->
                                    {{item.analysis}}
                                  </van-tag>
                              </div>
                            </template>
                        </van-cell>
                    </div>
                    <!-- <van-empty image-size="30rem" description="暂未试题"/> -->
              </div>
              <div v-else>
                  <van-empty image-size="30rem" description="暂未试题"/>
              </div>
        </van-tab>
      </van-tabs>


    </div>
    <pageLoading  v-show="showLoading"/>

  </div>
</template>

<script>
import {ImagePreview} from 'vant';
import {questionErrorSubject, questionCollectList, testcollect,} from "@/request/api";
import {Toast, Loading} from "vant";
import pageLoading from "@/components/pageLoading.vue";
export default {

  components: {pageLoading, ImagePreview},

  name: 'QuestionCollection',
  metaInfo: {
    title: '题目收藏', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // active:'',
      contentStyleObj: {
          height: '',
          width: ''
      },
        subjectList: [],
        subject_id: '',
        questionList: [],
      showLoading:true,

    }
  },
  created() {
    // 获取试题
      this.getQuestionCollecSubject();
    // this.getData()
  },
  mounted() {
      this.contentStyleObj.height = this.$refs.attrRef.offsetWidth * 9 / 16 + 'px ' + '!important';
      this.contentStyleObj.width = window.getComputedStyle(this.$refs.attrRef).width + ' ' + '!important';
  },

  methods: {

    getImg(images, index) {
      console.log(this.images)
      ImagePreview({
          images: images, //需要预览的图片 URL 数组
          showIndex: true, //是否显示页码
          loop: false, //是否开启循环播放
          startPosition: index //图片预览起始位置索引
      })
    },

    onBack() {
      history.back();
    },
      getQuestionCollecSubject() {
          let that = this;
          questionErrorSubject({})
              .then(res => {
                  if (res.data.code == 1) {
                      if(res.data.data.data.length <= 0){
                        that.showLoading = false;
                      }
                      that.subjectList = res.data.data.data;
                      try {
                        res.data.data.data.forEach(function (item, index) {
                          that.subject_id = item.id;
                          that.getQuestionCollecList();
                          throw Error();
                        })
                      }catch (e) {

                      }
                  }else{
                    that.showLoading = false;
                  }
              })
              .catch(err => {
                  // Toast.fail('请求错误1')
                that.showLoading = false;
                  console.log("error");
                  console.log(err);
              })
      },
      getQuestionCollecList() {
          let that = this;
          let subjectId = that.subject_id;
          questionCollectList({subjectId})
              .then(res => {
                that.showLoading = false;
                  if (res.data.code == 1) {
                      that.questionList = res.data.data.questions
                  }
              })
              .catch(err => {
                  // Toast.fail('请求错误1')
                that.showLoading = false;
                  console.log("error");
                  console.log(err);
              })
      },
      /**
       * 收藏
       */
      collection(id,type) {
          let that = this;
          testcollect({testid:id,type})
              .then(res => {
                  if (res.data.code == 1) {
                      Toast.success(res.data.msg);
                      that.getQuestionCollecList();
                  }
              })
              .catch(err => {
                  Toast.fail('操作错误');
              })
      },
      clickTab(name, title) {
          this.subject_id = name
          this.showLoading = true;
          this.getQuestionCollecList();
      },
  },
}
</script>

<style lang = "less" >
    .testList .van-tabs__line {
        bottom: 1rem;
        width: 2.6rem;
        height: 0.5rem;
        border-radius: 1rem;
    }
    .testList .van-sticky--fixed {
        position: fixed;
        top: 6rem;
        right: 0;
        left: 0;
        z-index: 99;
    }
    .testList .testData {
        margin: 0.5rem 0 1rem 0;
        background-color: #ffffff;
        padding-bottom: 1.5rem;
    }
    .testList .testData .van-cell {
        border-radius: 1rem !important;
        padding: 1.5rem;
    }
    .testList .testData .van-cell::after {
        border-width: 0 !important;
    }

    .testList .testData .answering .custom-title{
        font-size: 1.8rem;
        // font-weight: 600;
        padding-right: 1rem;
        line-height: 3rem;
    }
    .testList .testData .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }
    .testList .testData .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.6rem;
    }
    .testList .testData .answering .van-cell__label p {
        width: 100%;
        float: left;
        line-height: 3rem;
    }
    .testList .testData .comRadio_answer {
        padding: 1rem 0;
        font-size: 1.8rem;
        // border-top: 0.4rem solid #f6f7f9;
    }
    .testList .testData .comRadio_answer .my_answer {
        padding: 1rem 2.5rem 0.5rem 0rem;
        font-size: 1.8rem;
        color: #07c160;
    }
    .testList .testData .comRadio_answer .reference_answer_correct {
        padding: 1rem 0rem 0.5rem 0rem;
        font-size: 1.8rem;
        color: #ee0a24;
    }
    .testList .testData .comRadio_answer .knowledge_box .knowledge {
        padding: 0rem;
        font-size: 1.8rem;
        min-width: 12rem;
    }
    .testList .testData .comRadio_answer .accuracy_box .accuracy {
        padding: 0rem;
        font-size: 1.8rem;
        min-width: 12rem;
    }
    .testList .testData .comRadio_answer .accuracy_box .accuracy-text {
        padding: 0rem;
        font-size: 1.8rem;
    }
    .testList .testData .comRadio_video_analysis {
        padding: 0;
        // border-top: 0.4rem solid #f6f7f9;
    }
    .testList .testData .comRadio_video_analysis .van-cell__title {
        height: 1.8rem;
        line-height: 1.8rem;
        text-align: left;
        border-left: 0.5rem solid #1989fa;
        padding-left: 1rem;
        font-size: 1.8rem;
    }
    .testList .testData .comRadio_analysis .van-cell__title {
        height: 1.8rem;
        line-height: 1.8rem;
        text-align: left;
        border-left: 0.5rem solid #1989fa;
        padding-left: 1rem;
        font-size: 1.8rem;
    }
    .testList .testData .comRadio_analysis {
        padding: 0;
        // border-top: 0.4rem solid #f6f7f9;
    }
    .testList .testData .comRadio_analysis .van-tag {
        font-size: 1.8rem !important;
        line-height: 2.4rem !important;
        border-radius: 0.5rem !important;
    }


    .testList .testData .answering .testType{
        height: 2rem;
        line-height: 2rem;
        text-align: left;
        border-left: 0.5rem solid #1989fa;
        padding-left: 1rem;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    .testList .testData .answering .addtime{
        // float: right;
        font-size: 1.8rem;
        color: #1989fa;
        position: absolute;
        right: 1rem;
        top: 1.5rem;
    }
    .testList .testData .answering .isStem .data_value{
        // float: right;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .testList .testData .project_tag{
      position: absolute;
      left: 1rem;
      // float: left;
      padding: 0.3rem 0.8rem !important;
      font-size: 1.5rem !important;
      z-index: 99999;
      // border-top-left-radius: 1rem;
    }
    .testList .testData .reply_tag{
      background-color: #f5f5f5;
      border-radius: 3rem;
      // padding-left: 0.3rem;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 90%;
      margin: 0 1rem;
    }
    .testList .testData .reply_tag .van-tag{
      height: 2.8rem !important;
      // width: 3rem !important;
      border-radius: 3rem;
      padding: 0.1rem 1rem !important;
      font-size: 1.8rem !important;
    }
    .testList .testData .reply_tag .reply_text{
      padding-left: 0.2rem;
      font-size: 1.8rem;
      color: #646566;
    }
    .icon-img{
      width: 8rem;

    }

</style>
