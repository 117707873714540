<template>
  <div class="comShortAnswer">
    <div class="comShortAnswer_title" v-if="type=='0'">
      <!--问答题题干使用，需要判断是否是题干题-->
      <div class="stem" v-if="obj.data_title != ''">
        <span  v-html="obj.data_title" class="data_value"></span>
        <div></div>
        <!-- 显示图片 -->
        <span v-for="(item,index) in obj.data_img" :key="index" v-show="item">
              <van-image
                      v-if="item"
                      width="20rem"
                      height="10rem"
                      class="title_img"
                      fit="cover"
                      :src="item"
                      @click="getImg(obj.data_img, index)"
              />
          </span>
        <!-- 显示视频 -->
        <span v-for="(item,index) in obj.data_video" :key="'video-'+index" >
              <video
                      id="myVideo"
                      class="video-js"
                      controls
                      preload="auto"
                      :poster="obj.videoAnalysisPoster"
                      :style="contentStyleObj"
              >
              <source :src="item" type="video/mp4">
              </video>
          </span>
      </div>
      {{$store.state.currentIndex+1}}.
      <span style="margin-left:0.5rem">{{obj.title}}</span>
      <span class="comShortAnswer_scoreClass">（{{obj.score}}分）</span>
    </div>
    <div class="comShortAnswer_text">
      <!-- <textarea :disabled="type==='1'?true:false" cols="25" rows="10" v-model="obj.answer"></textarea> -->
      
      <van-cell-group inset>
        <van-field
          v-model="obj.answer"
          rows="6"
          autosize
          type="textarea"
          maxlength="300"
          placeholder="请输入内容..."
          :disabled="type=='3'?true:false"
          show-word-limit
          @input="inputChange($event)"
        />
      </van-cell-group>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  props: ['obj', 'type','answerStatus'],
  data() {
    return {}
  },
  watch: {},
  created() {},
  methods: {
    inputChange($event) {
        if(this.answerStatus == true) {
            return false;
        }
      this.$store.state.zxksList[this.$store.state.currentIndex].answer = $event;
      this.obj.answer = $event;
    }
  }
}
</script>
 
<style scoped>
.comShortAnswer_title {
  /* margin-top: 1rem; */
  width: auto;
  text-align: left;
  padding: 0 1rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
}
.comShortAnswer_scoreClass {
  color: #a0a0a0;
}

.comShortAnswer_text {
  margin: 1rem;
  border: 0.1rem solid gainsboro;
  background: transparent;
  border-radius: 1rem;
}

</style>
