<template>
  <div class="com_zxksTip">
    <div class="com_zxksTip_title">
      <van-cell center>
        <template #title>
          {{title}}
        </template>
        <template #right-icon>
          <img @click="cancelFun()" src="https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_close.png" alt>
        </template>
      </van-cell>
      <!-- <div>{{title}}</div>
      <div>
        <img @click="cancelFun()" src="https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_close.png" alt>
      </div> -->
    </div>
    <div v-if="type==='ts'" class="com_zxksTip_content">{{tip}}</div>
    <van-form @submit="onSubmit" class="login-form">
      <div v-if="type==='tw'" class="com_zxksTip_content">
        <van-field
          v-model="twVal"
          rows="4"
          clearable
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请输入..."
          show-word-limit
          name="twVal"
        />
      </div>
      <div v-if="type==='bj'" class="com_zxksTip_content">
        <van-field
          v-model="bjVal"
          rows="4"
          clearable
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请输入发言内容"
          show-word-limit
        />
      </div>
      <div class="com_zxksTip_btn" v-if="type==='tw'||type==='bj'">
        <van-button round type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>
 
<script>
//import * from '@/*/*'
import {Toast} from "vant";
import {questionAnswerAdd} from "@/request/api";

export default {
  components: {},
  props: ['tip', 'title', 'type','obj','commodityId','testPaperId'],
  data() {
    return {
      // 提问
      twVal: '',
      //笔记
      bjVal: ''
    }
  },
  watch: {},
  created() {},
  methods: {
    // 取消弹框
    cancelFun() {
      this.$emit('cancelMask')
    },
    onSubmit(values) {
      let that = this;
      let answer = values["twVal"];
      let id = that.obj.id;
      let commodityId = that.commodityId;
      let testPaperId = that.testPaperId;
      if(answer == '') {
        Toast.fail('请输入答疑内容');
        return false;
      }
      questionAnswerAdd({commodityId,testPaperId,id,content:answer,source:2}).then(res => {
        if (res.data.status == 1) {
          // 登录成功了
          // 1.提示框提示登录成功
          Toast.success(res.data.msg);
          this.$emit('answerQuestion')
        } else {
          Toast.fail(res.data.msg)
        }
      })
    },
  }
}
</script>
 
<style scoped>
.com_zxksTip {
  overflow-y: hidden;
}
.com_zxksTip /deep/ .van-field__word-num {
  color: red;
}
.com_zxksTip_title {
  height: 6rem;
  line-height: 6rem;
  padding: 0 1.5rem;
  display: flex;
  border-bottom: 0.1rem solid #e7e9ed;
}
.com_zxksTip_title img {
  width: 3rem;
  height: 3rem;
}
.com_zxksTip_content {
  width: 96%;
  text-align: left;
  height: 20rem;
  padding: 1rem;
  /* margin: 1rem 2%; */
  line-height: 3rem;
  overflow-y: auto;
  margin-bottom: 3rem;
}
.com_zxksTip_btn /deep/ .van-button {
  width: 90%;
  height: 10rem;
  line-height: 10rem;
  margin-left: 5%;
  border-radius: 10rem;
}
</style>
