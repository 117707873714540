<template>
  <div class="zxksClass">
    <!-- 导航栏 -->
    <comnavBar :Title="Title" :backType="'0'" @onClickLeft="onClickLeft" @click-right="onClickRight"></comnavBar>
    <!-- 标签页 -->
    <div class="zxks_title">
      <div :class="currentActive==='dk'?'zxks_title_ok':'zxks_title_no'" @click="switchFun('dk')">待考</div>
      <div :class="currentActive==='yk'?'zxks_title_ok':'zxks_title_no'" @click="switchFun('yk')">已考</div>
    </div>
    <!-- 考试列表 -->
    <div class="zxks_scroll">
      <div class="zxks_div" v-if="currentActive==='dk'">
        <div
          class="zxks_list"
          v-for="(item,index) in examObj.dkList"
          :key="index"
          @click="ksxxFun(item)"
        >
          <div class="zxks_list_one">
            <div>{{item.title}}</div>
            <div>
              <span
                :class="item.state==='0'?'zxks_list0':(item.state==='1'?'zxks_list1':'zxks_list2')"
                v-text="item.state==='0'?'未开始':(item.state==='1'?'进行中':'已结束')"
              ></span>
            </div>
          </div>
          <div class="zxks_list_two">
            <span>
              {{item.dateStart}}
              <a style="margin-left:1vw">{{item.timeStart}}</a>
              ~
              <a style="margin-left:1vw">{{item.dateEnd}}</a>
              <a style="margin-left:1vw">{{item.timeEnd}}</a>
            </span>
          </div>
          <div class="zxks_list_three">
            <span>
              共{{item.zts}}题，{{item.yjk}}人参与
            </span>
          </div>
        </div>
      </div>
      <!-- 已考列表 -->
      <div class="zxks_div" v-if="currentActive==='yk'">
        <div
          class="zxks_list"
          v-for="(item,index) in examObj.ykList"
          :key="index"
          @click="ksxxFun(item)"
        >
          <div class="zxks_list_one">
            <div>{{item.title}}</div>
            <div>
              <span
                :class="item.state==='0'?'zxks_list0':(item.state==='1'?'zxks_list1':'zxks_list2')"
                v-text="item.state==='0'?'未开始':(item.state==='1'?'进行中':'已结束')"
              ></span>
            </div>
          </div>
          <div class="zxks_list_two">
            <span>{{item.dateStart}}{{item.timeStart}}~{{item.dateEnd}}{{item.timeEnd}}</span>
          </div>
          <div class="zxks_list_three">
            <span>
              共{{item.zts}}题，{{item.yjk}}人参与
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出层（考试信息） -->
    <van-popup v-model="showKS" :position="bottom" :style="{ height: '90%',width:'95%' }">
      <zxksKsxx :currentInfo="currentInfo" @closeFun="closeFun"></zxksKsxx>
    </van-popup>
  </div>
</template>
 
<script>
// 导航栏
import comnavBar from '@/components/com_navBar'
// 考试信息的弹框
import zxksKsxx from '@/modules/zxks_ksxx'
export default {
  components: { comnavBar, zxksKsxx },
  data() {
    return {
      // 导航标题
      Title: '在线考试',
      // 当前选中的标签页 (默认：代考)
      currentActive: 'dk',
      // 考试数据
      examObj: {},
      // 点击展示正常考试的信息
      showKS: false,
      // 传给子组件的考当前试信息
      currentInfo: {}
    }
  },
  watch: {},
  created() {
    // 获取考试列表
    this.getData()
  },
  methods: {
    // 返回
    onClickLeft() {
      this.$router.push({ name: 'Question' })
    },
    // 点击考试信息的遮罩层
    closeFun() {
      this.showKS = false
    },
    // 返回（考试信息）
    backFun() {
      this.showKS = false
    },
    // 查看考试信息
    ksxxFun(val) {
      this.showKS = true
      this.currentInfo = val
    },
    // 切换标签页
    switchFun(val) {
      this.currentActive = val
    },
    // 获取考试列表
    getData() {
      this.examObj.dkList = [
        {
          title: '2022年中药学专业知识一《模考试卷》', //考试名称
          state: '0', //考试的状态   0:未开始   1：进行中   2：已结束
          dateStart: '2020-06-30', //开始日期
          dateEnd: '2020-06-31', //截止日期
          timeStart: '14:00', //开始时间点
          timeEnd: '15:30', //截止时间点
          kssm: '考试说明中药一一', //考试说明
          yk: 50, //应考人数
          yjk: 4651, //已考人数
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        },
        {
          title: '2022年中药学专业知识二《模考试卷》',
          state: '1',
          dateStart: '2020-06-30',
          dateEnd: '2020-06-31',
          timeStart: '14:00',
          timeEnd: '15:30',
          yk: 50,
          yjk: 20,
          kssm: '考试说明中药二',
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        },
        {
          title: '2022年中药学综合知识与技能《模考试卷一》',
          state: '2',
          dateStart: '2020-06-30',
          dateEnd: '2020-06-31',
          timeStart: '14:00',
          timeEnd: '15:30',
          yk: 50,
          yjk: 20,
          kssm: '最佳选择题（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）（每题1分，共40题，共40分）最佳选择题（每题1分，共40题，共40分）',
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        },
        {
          title: '2022年药事管理与法规《模考试卷一》',
          state: '2',
          dateStart: '2020-06-30',
          dateEnd: '2020-06-31',
          timeStart: '14:00',
          timeEnd: '15:30',
          yk: 50,
          yjk: 20,
          kssm: '考试说明中药综合',
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        },
        {
          title: '2022年药学专业知识一《模考试卷一》',
          state: '2',
          dateStart: '2020-06-30',
          dateEnd: '2020-06-31',
          timeStart: '14:00',
          timeEnd: '15:30',
          yk: 50,
          yjk: 20,
          kssm: '考试说明中药综合',
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        },
        {
          title: '2022年药学专业知识二《模考试卷二》',
          state: '2',
          dateStart: '2020-06-30',
          dateEnd: '2020-06-31',
          timeStart: '14:00',
          timeEnd: '15:30',
          yk: 50,
          yjk: 20,
          kssm: '考试说明中药综合',
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        },
        {
          title: '2022年药学综合知识与技能《模考试卷一》',
          state: '2',
          dateStart: '2020-06-30',
          dateEnd: '2020-06-31',
          timeStart: '14:00',
          timeEnd: '15:30',
          yk: 50,
          yjk: 20,
          kssm: '考试说明中药综合',
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        }
      ]
      this.examObj.ykList = [
        {
          title: '2020年知识竞赛2020年知识竞赛',
          state: '1',
          dateStart: '2020-06-30',
          dateEnd: '2020-06-31',
          timeStart: '14:00',
          timeEnd: '15:30',
          yk: 50,
          yjk: 20,
          kssm: '考试说明',
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        },
        {
          title: '2020年知识竞赛2020年知识竞赛',
          dateStart: '2020-06-30',
          dateEnd: '2020-06-31',
          timeStart: '14:00',
          timeEnd: '15:30',
          yk: 50,
          yjk: 20,
          kssm: '考试说明',
          mf: 100, //满分
          jgf: 60, //及格分
          zts: 120, //总题数
          kssc: 120 //考试时长
        }
      ]
    }
  }
}
</script>
 
<style scoped>

.zxksClass {
  width: 100%;
  height: 100%;
  background: #e7e9ed;
  /* padding-top: 15vw; */
  overflow-y: hidden;
}
.zxksClass /deep/ .van-popup {
  border-radius: 1.5rem;
  overflow: hidden;
}
.zxks_title {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  position: fixed;
  display: flex;
  padding: 7rem 0 1rem 0;
  justify-content: center;
  background: #e7e9ed;
}
.zxks_title > div {
  width: 30%;
  margin: 0 2rem;
  font-size: 2rem;
  box-shadow: 0 0 1rem gainsboro;
}
.zxks_title_ok {
  background: #ee0a24;
  color: #ffffff;
  border-radius: 1rem;
  text-align: center;
}
.zxks_title_no {
  background: #ffffff;
  border-radius: 1rem;
  text-align: center;
}



.zxks_scroll {
  height: auto;
  margin:  13rem 1rem 1.5rem 1rem;
  border-radius: 1rem;
  overflow-y: scroll;
}
.zxks_div {
  background: #ffffff;
}
.zxks_list {
  width: auto;
  padding: 1rem;
  border-bottom: 0.1rem solid #e7e9ed;
  /* margin-top: 4vw; */
}
.zxks_list_one {
  display: flex;
  font-size: 2rem;
  line-height: 3rem;
}
.zxks_list_one > div:nth-child(1) {
  width: auto;
  padding: 1.5rem 0.6rem 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  text-align: left;
}
.zxks_list_one > div:nth-child(2) {
  width: 7rem;
  padding-top: 1.3rem;
}
.zxks_list_two {
  color: #969799;
  text-align: left;
  padding: 1rem ;
  font-size: 1.8rem;
  line-height: 1.8rem;
}
.zxks_list_three {
  color: #969799;
  text-align: left;
  font-size: 1.8rem;
  line-height: 1.8rem;
  padding: 0.6rem 1rem 1.5rem 1rem;
}
.zxks_list0 {
  border: 0.1rem solid #ffb700;
  color: #ffb700;
  padding: 0.2rem 0.5rem;
  font-size: 1.4rem;
  border-radius: 0.5rem;
}
.zxks_list1 {
  border: 0.1rem solid #eb3637;
  padding: 0.2rem 0.5rem;
  font-size: 1.4rem;
  border-radius: 0.5rem;
  color: #eb3637;
}
.zxks_list2 {
  border: 0.1rem solid #969799;
  color: #969799;
  padding: 0.2rem 0.5rem;
  font-size: 1.4rem;
  border-radius: 0.5rem;
}
</style>
