<template>
    <div class="calculator" 
        id="moveDiv"
        @mousedown="down" @touchstart="down"
        @mousemove="move" @touchmove="move"
        @mouseup="end" @touchend="end"
        v-if="ishide"
    >
            <div class="close">
              <img src="http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_close.png" @click="close">
            </div>
            <div class="inputbd">
            <span class="m">{{memory}}</span>
            <input maxLength=8 v-model="display" />
            </div>
            <div class="content">
            <div class="btn-group-justified">
                <button class="btn btn-calc-action" @click="memoryread()">MR</button>
                <button class="btn btn-calc-action" @click="memoryclear()">MC</button>
                <button class="btn btn-calc-action" @click="memoryadd()">M+</button>
                <button class="btn" @click="addbracket('(')" :disabled="bracketStatus">(</button>
                <button class="btn" @click="disbracket(')')" :disabled="!bracketStatus">)</button>
            </div>
            <div class="btn-group-justified">
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('7')">7</button>
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('8')">8</button>
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('9')">9</button>
                <button class="btn btnactive" @click="operation('/',6)">÷</button>
                <button class="btn btn-calc-action-secondary" @click="clearall()">C</button>
            </div>
            <div class="btn-group-justified">
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('4')">4</button>
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('5')">5</button>
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('6')">6</button>
                <button class="btn btnactive" @click="operation('*',6)">×</button>
                <button class="btn btn-calc-action-secondary" @click="backspace()">CE</button>
            </div>
            <div class="btn-group-justified">
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('1')">1</button>
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('2')">2</button>
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('3')">3</button>
                <button class="btn btnactive" @click="operation('-',5)">-</button>
                <button class="btn btn-calc-action-secondary" @click="squareroot()">√</button>
            </div>
            <div class="btn-group-justified">
                <button class="btn" @click="changeSign()">±</button>
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('0')">0</button>
                <button class="btn btn-calc-digit tc-no-focus" @click="inputkey('.')">.</button>
                <button class="btn btnactive" @click="operation('+',5)">+</button>
                <button class="btn btn-calc-action" @click="result()">=</button>
            </div>
            <div class="btn-group-justified">
                <button class="btn btn-transfer-display">Transfer Display</button>
            </div>
            </div>
        </div>
</template>
<script>


export default {
  data() {
    return {
      // 计算器
      display: "0",
      operator: "",
      endNumber: true,
      mem: 0,
      memory: '',
      carry: 10,
      layer: 0,
      angle: "d",
      stack: "",
      level: "0",
      bracket: "",
      lastOperator: "",
      isMaxLen: false,
      completeEprsPanel: "",
      hexnum: "0123456789abcdef",
      bracketStatus: false,
      flags: false,
      position: { x: 0, y: 0 },
      nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
    }
  },
  props: {
    ishide: {
        type: Boolean,
        default: false
      },
  },
  methods: {
      // 实现移动端拖拽
    down(){
        this.flags = true;
        var touch;
        if(event.touches){
            touch = event.touches[0];
        }else {
            touch = event;
        }
        this.position.x = touch.clientX;
        this.position.y = touch.clientY;
        this.dx = moveDiv.offsetLeft;
        this.dy = moveDiv.offsetTop;
    },
    move(){
        if(this.flags){
            var touch ;
            if(event.touches){
                touch = event.touches[0];
            }else {
                touch = event;
            }
            this.nx = touch.clientX - this.position.x;
            this.ny = touch.clientY - this.position.y;
            this.xPum = this.dx+this.nx;
            this.yPum = this.dy+this.ny;
            this.xPum = this.xPum > 795 ? 795 : this.xPum < 5 ? 5 : this.xPum;
            this.yPum = this.yPum > 420 ? 420 : this.yPum < 0 ? 0 : this.yPum;
            moveDiv.style.left = this.xPum+"px";
            moveDiv.style.top = this.yPum +"px";
            //阻止页面的滑动默认事件

            document.addEventListener("touchmove",function(){ // 1.2 如果碰到滑动问题，请注意是否获取到 touchmove
            event.preventDefault();//jq 阻止冒泡事件
            // event.stopPropagation(); // 如果没有引入jq 就用 stopPropagation()
            },false);
        }
    },
    //鼠标释放时候的函数
    end(){
        this.flags = false;
        this.ishide = true
    },
    close() {
      this.$emit('close')
    },
    // 数字0-9和.
    inputkey(key) {
      if (this.isMaxLen == true) {
        return;
      }
      if (this.lastOperator == "=") {
        this.completeEprsPanel = key;
      } else {
        if (this.lastOperator == "m") {
          this.completeEprsPanel = "";
        }
        this.completeEprsPanel += key;
      }
      var index = key.charCodeAt(0);
      if (
        (this.carry == 2 && (index == 48 || index == 49)) ||
        (this.carry == 8 && index >= 48 && index <= 55) ||
        (this.carry == 10 && ((index >= 48 && index <= 57) || index == 46)) ||
        (this.carry == 16 &&
          ((index >= 48 && index <= 57) || (index >= 97 && index <= 102)))
      ) {
        if (this.endNumber) {
          this.endNumber = false;
          if (index == 46) {
            if (this.display.indexOf(".") != -1) {
              //
            } else {
              this.display += key;
            }
          } else {
            this.display = key;
          }
        } else if (this.display == null || this.display == "0") {
          if (index == 46) {
            if (this.display.indexOf(".") != -1) {
              //
            } else {
              this.display += key;
            }
          } else {
            this.display = key;
          }
        } else {
          if (index == 46) {
            if (this.display.indexOf(".") != -1) {
              //
            } else {
              this.display += key;
            }
          } else {
            this.display += key;
          }
        }
      }
      this.lastOperator = "";
      if (this.display.length > 16) {
        this.isMaxLen = true;
      }
    },
    // 加减乘除
    operation(join, newlevel) {
      this.endNumber = true;
      var temp =
        this.stack.substr(this.stack.lastIndexOf("(") + 1) + this.display;
      while (
        newlevel != 0 &&
        newlevel <= this.level.charAt(this.level.length - 1)
      ) {
        temp = this.parse(temp);
        this.level = this.level.slice(0, -1);
      }

      if (temp.match(/^(.*\d[\\+\-\\*\\/\\%\\^\\&\\|x])?([+-]?[0-9a-f\\.]+)$/));
      this.display = RegExp.$2;

      this.stack =
        this.stack.substr(0, this.stack.lastIndexOf("(") + 1) + temp + join;
      this.operator = " " + join + " ";
      this.level = this.level + newlevel;

      this.completeEprsPanel += this.operator;
      this.lastOperator = join;
      this.isMaxLen = false;
    },
    // 正负号
    changeSign() {
      if (this.display != "0") {
        if (this.display.substr(0, 1) == "-") {
          this.display = this.display.substr(1);
        } else {
          this.display = "-" + this.display;
        }
      }
    },
    // 等号
    result() {
      this.endNumber = true;
      while (this.layer > 0) {
        this.disbracket();
      }
      var temp = this.stack + this.display;
      while (this.level.charAt(this.level.length - 1) > 0) {
        temp = this.parse(temp);
        this.level = this.level.slice(0, -1);
      }
      this.display = temp;
      this.bracket = "";
      this.operator = "";
      this.stack = "";
      this.level = "0";
      this.lastOperator = "=";
      this.isMaxLen = false;
    },
    // 清除
    clearall() {
      this.bracketStatus = false;
      this.display = 0;
      this.endNumber = true;
      this.stack = "";
      this.level = "0";
      this.layer = "";
      this.operator = "";
      this.bracket = "";
      this.completeEprsPanel = "";
      this.isMaxLen = false;
    },
    // 删除
    backspace() {
      try {
        this.completeEprsPanel = this.completeEprsPanel.substring(
          0,
          this.completeEprsPanel.length - 1
        );
      } catch (e) {
        //
      }
      if (!this.endNumber) {
        if (this.display.length > 1) {
          this.display = this.display.substring(0, this.display.length - 1);
        } else {
          this.display = 0;
        }
      }
      if (this.display.length <= 16) {
        this.isMaxLen = false;
      }
    },
    // 左括号
    addbracket(obj) {
      this.bracketStatus = true;
      this.endNumber = true;
      this.display = 0;
      this.stack = this.stack + obj;
      this.operator = "   ";
      this.level = this.level + 0;
      this.layer += 1;
      this.bracket = obj + "=" + this.layer;
      if (this.lastOperator == "=") {
        this.completeEprsPanel = obj;
      } else {
        if (this.lastOperator == "m") {
          this.completeEprsPanel = "";
        }
        this.completeEprsPanel += obj;
      }
      this.lastOperator = obj;
    },
    // 右括号
    disbracket(value) {
      this.bracketStatus = false;
      this.endNumber = true;
      var temp =
        this.stack.substr(this.stack.lastIndexOf("(") + 1) + this.display;
      while (this.level.charAt(this.level.length - 1) > 0) {
        temp = this.parse(temp);
        this.level = this.level.slice(0, -1);
      }
      this.display = temp;
      this.stack = this.stack.substr(0, this.stack.lastIndexOf("("));
      this.operator = "   ";
      this.level = this.level.slice(0, -1);
      this.layer -= 1;
      if (this.layer > 0) {
        this.bracket = "(=" + this.layer;
      } else {
        this.bracket = "";
      }
      if (this.lastOperator == "=") {
        this.completeEprsPanel = value;
      } else {
        if (this.lastOperator == "m") {
          this.completeEprsPanel = "";
        }
        this.completeEprsPanel += value;
      }
      this.lastOperator = value;
    },
    // Memery clear 清除缓存
    memoryclear() {
      this.mem = 0;
      this.memory = "";
    },
    // Memery Read 读取缓存
    memoryread() {
      this.endNumber = true;
      this.display = this.decto(this.mem, this.carry);
    },
    // Memery add 添加缓存
    memoryadd() {
      this.endNumber = true;
      if (this.display != 0) {
        this.mem = this.todec(this.display, this.carry);
        this.memory = "M";
      } else {
        this.memory = "M";
      }
      this.lastOperator = "m";
    },
    // 根号
    squareroot() {
      this.display = Math.sqrt(this.display);
    },
    parse(string) {
      if (
        string.match(
          /^(.*\d[\\+\-\\*\\/\\%\\^\\&\\|x\\<])?([+-]?[0-9a-f\\.]+)([\\+\-\\*\\/\\%\\^\\&\\|x\\<])([+-]?[0-9a-f\\.]+)$/
        )
      ) {
        return RegExp.$1 + this.cypher(RegExp.$2, RegExp.$3, RegExp.$4);
      } else {
        return string;
      }
    },
    cypher(left, join, right) {
      left = this.todec(left, this.carry);
      right = this.todec(right, this.carry);
      if (join == "+")
        return this.decto(parseFloat(left) + parseFloat(right), this.carry);
      if (join == "-") return this.decto(left - right, this.carry);
      if (join == "*")
        return this.decto(this.floatMul(left, right), this.carry);
      if (join == "/" && right != 0)
        return this.decto(this.floatDiv(left, right), this.carry);
      if (join == "%") return this.decto(left % right, this.carry);
      if (join == "&") return this.decto(left & right, this.carry);
      if (join == "|") return this.decto(left | right, this.carry);
      if (join == "^") return this.decto(Math.pow(left, right), this.carry);
      if (join == "x") return this.decto(left ^ right, this.carry);
      if (join == "<") return this.decto(left << right, this.carry);
      alert("除数不能为零");
      return left;
    },
    todec(num, oldcarry) {
      if (oldcarry == 10 || num == 0) return num;
      var neg = num.charAt(0) == "-";
      if (neg) num = num.substr(1);
      var newnum = 0;
      for (var index = 1; index <= num.length; index++)
        newnum = newnum * oldcarry + this.hexnum.indexOf(num.charAt(index - 1));
      if (neg) newnum = -newnum;
      return newnum;
    },
    decto(num, newcarry) {
      var neg = num < 0;
      if (newcarry == 10 || num == 0) return num;
      num = "" + Math.abs(num);
      var newnum = "";
      while (num != 0) {
        newnum = this.hexnum.charAt(num % newcarry) + newnum;
        num = Math.floor(num / newcarry);
      }
      if (neg) newnum = "-" + newnum;
      return newnum;
    },
    floatMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {
        //
      }
      try {
        m += s2.split(".")[1].length;
      } catch (e) {
        //
      }
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    floatDiv(arg1,arg2){
      var t1=0,t2=0,r1,r2;
      try{t1=arg1.toString().split(".")[1].length}catch(e){
        //
      }    
      try{t2=arg2.toString().split(".")[1].length}catch(e){
        //
      }    
      r1=Number(arg1.toString().replace(".",""))
      r2=Number(arg2.toString().replace(".",""))
      return (r1/r2)*Math.pow(10,t2-t1);    
    }
  },
};
</script>

<style lang="less" scoped>
// 计算器
button:disabled{
  opacity: .35;
}
.calculator {
  width: 224px;
  font-size: 16px;
  border: 1px solid #bbb;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 45%);
  -webkit-box-shadow: 3px 3px 10px rgb(0 0 0 / 45%);
  -moz-box-shadow: 3px 3px 10px rgba(0,0,0,.45);
  background: #d3d3d3;
  padding-bottom: 5px;
  word-wrap: break-word;
  z-index: 600;
  position: absolute;
  font-weight: 400;
  padding: 5px 5px 10px;
  cursor: move;
  top:100px;
  right:10px;
}
.close{
  width:100%;
  height:22px;
  margin-bottom: 5px;
}
.close img{
  width:22px;
  height:22px;
  cursor: pointer;
  float: right!important;
}

.inputbd{
    background-color: #fff;
    margin-bottom: 5px;
    border: 1px solid #dcdcdc;
    height:42px;
    line-height: 42px;
    border-radius: 4px;
    padding-right:15px;
    position: relative;
}
.inputbd input {
     border: none;
    text-align: right;
    font-size: 21px;
    color: #000;
    font-weight: 700;
    width: 210px;
    height: 40px;
}
.m{
  position: absolute;
  left: 0;
  width: 23px;
  display: block;
  z-index: 999;
  height: 40px;
  padding-left:10px;
  background: #fff;
}
input:focus,
input:active {
  border: none;
  outline: none 0;
}

.btn-group-justified{
    table-layout: auto;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
}
.btn {
    padding: 0!important;
    height: 40px;
    float: left;
    text-align: center;
    min-width: 40px!important;
    border-radius: 4px;
    margin: 1px;
    color: #fff;
    font-weight: 700;
    font-size: 21px;
    background-color: #3a77a0;
    box-shadow: inset -1px -1px 0 #27536b, inset -1px 1px 0 #4d7991, inset 1px -1px 0 #4d7991, inset 1px 1px 0 #27536b;
    outline: 0;
    border: 1px solid transparent;
    cursor: pointer;
}

.btn-calc-action{
    background-color: #234760;
    box-shadow: inset -1px -1px 0 #1e3b51, inset -1px 1px 0 #436177, inset 1px -1px 0 #436177, inset 1px 1px 0 #1e3b51;
    outline: 0;
    color: #fff;
    padding: 5px;
    font-weight: 400;
}

.btn-calc-digit {
    color: #3a3736;
    background-color: #ebf0f5;
    box-shadow: inset -1px -1px 0 #a7a4a9, inset -1px 1px 0 #edf1f5, inset 1px -1px 0 #edf1f5, inset 1px 1px 0 #a7a4a9;
    font-weight: 400;
    font-size: 24px;
    padding: 5px;
}

.btn-calc-action-secondary{
    background-color: #BA5A13;
    box-shadow: inset -1px -1px 0 #ad5812, inset -1px 1px 0 #d37e37, inset 1px -1px 0 #d37e37, inset 1px 1px 0 #ad5812;
    outline: 0;
    padding: 5px;
    color: #fff;
    font-weight: 400;
}

.btn-transfer-display{
    background-color: #47565e;
    color: #d7d7d7;
    width: 208px;
    opacity: .35;
    cursor: pointer;
}

.btn.tc-no-focus:active:focus,.btn.tc-no-focus:focus,.tc-no-focus:focus {
	box-shadow:none;
	outline:0
}
.btn.tc-no-focus:hover {
	box-shadow:none;
	outline:0
}

.calculator .btn-calc-action:hover{
	border:1px solid #234760;
	box-shadow:inset 1px 1px 0 #fff,inset -1px -1px 0 #fff
}

.btnactive:hover {
	border:1px solid #3a77a0;
	box-shadow:inset 1px 1px 0 #fff,inset -1px -1px 0 #fff
}

.btn-calc-action-secondary:hover {
	border:1px solid #BA5A13;
	box-shadow:inset 1px 1px 0 #fff,inset -1px -1px 0 #fff
}
.calculator .btn-calc-action:active{
	background-color:#142935;
	box-shadow:inset -1px -1px 0 #364852,inset -1px 1px 0 #10222c,inset 1px -1px 0 #10222c,inset 1px 1px 0 #364852
}
 .btnactive:active {
	background-color:#3a77a0;
	box-shadow:inset -1px -1px 0 #364852,inset -1px 1px 0 #10222c,inset 1px -1px 0 #10222c,inset 1px 1px 0 #364852
}

 .btn-calc-action-secondary:active {
  border:1px solid #BA5A13;
  box-shadow:inset 1px 1px 0 #fff,inset -1px -1px 0 #fff
}


.disabled{
  opacity: .35;
}

.button {
  margin: 3px;
  width: 63px;
  border: 1px solid #0d0d0d;
  height: 30px;
  border-radius: 4px;
  color: #d9d9d9;
  background-color: #1a1a1a;
  cursor: pointer;
  outline: none;
}

.mode {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.equal-sign {
  background-color: green;
  width: 133px;
}

.toggle-button {
  border: none;
  background-color: #333333;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  color: #fff;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.35);
}

p {
  margin-top: 0;
}

button::-moz-focus-inner {
  border-color: transparent;
}

</style>

