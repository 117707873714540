<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        错题集
      </template>
    </van-nav-bar>
    <div style="padding: 6rem 0 22rem 0;background-color: #ffffff;">
      <div class="question-subject-tab" v-if="subjectList.length>0" style="padding: 0rem 1rem 0.5rem 1rem;">
        <van-tabs v-model:active="active" class="testList" color="#1989fa" swipeable sticky @click="clickTab">
          <van-tab :title="item.abbreviation_name" :name="item.id" v-for="(item, index) in subjectList" :key="index">
            <div style="padding-top: 0.5rem;">
              <div v-for="(item2, index2) in questionErrorList" :key="index2">
                <van-cell class="question-exam" center @click="getChildTestPaper(2,item2.id,index2)">
                  <template #icon>
                    <van-icon class="left-icon" :name="icon_minus" v-if="item2.selectChapter" /> <!--加载下一级 已打开 @click="closedKey(index2)"-->
                    <van-icon class="left-icon" :name="icon_plus"  v-else />
                  </template>
                  <template #title>
                    <span class="question-exam-chapter">{{ item2.title }}</span>
                    <p class="label" v-if="item2.count > 0" style="font-size: 1.8rem;color: #969799;padding-top: 1rem;">共{{ item2.count }}题</p>
                  </template>
                  <template #right-icon v-if="item2.status == true">
                    <van-tag  color="#ee0a24" round plain size="medium" @click.stop="questionPractice(item2.id,1)">练习</van-tag>
                    <van-tag  color="#1989fa" round plain size="medium" @click.stop="questionDetail(item2.id,1)">查看</van-tag>
                  </template>
                </van-cell>

                <!-- 第二级分类 -->
                <van-collapse class="question-exam-collapse" v-model="activeNames"  v-for="(item3, index3) in item2.data" :key="index3" v-show="item2.selectChapter" @change="getChildTestPaper(3,item3.id,index2,index3)">
                  <van-collapse-item :name="`${index2}`+`${index3}`">
                    <template #title>
                      <div class="question-exam-list">
                        <van-icon class="left-icon" :name="icon_minus" v-if="item3.selectChapter" /> <!--加载下一级 已打开  @click="closedKey(index2,index3)"-->
                        <van-icon class="left-icon" :name="icon_plus"  v-else /> <!--加载下一级-->
                        <p class="vertical-line"></p>
                        <span>
                        {{ item3.title }}
                        <p class="label" v-if="item3.count > 0">共{{ item3.count }}题</p>
                      </span>
                      </div>
                    </template>
                    <template #right-icon  v-if="item3.status == true">
                      <van-tag  color="#ee0a24" round plain size="medium" @click.stop="questionPractice(item3.id,2)">练习</van-tag>
                      <van-tag  color="#1989fa" round plain size="medium" @click.stop="questionDetail(item3.id,2)">查看</van-tag>
                    </template>

                    <!-- 第三级分类 -->
                    <div v-for="(item4, index4) in item3.data" :key="index4">
                      <van-cell class="question-exam-children" center :title="item4.title" @click.stop="questionDetail(item4.id,3)">
                        <template #icon>
                          <van-icon class="left-icon" :name="icon_choice" />
                          <p class="vertical-line"></p>
                        </template>
                        <template #label v-if="item4.count > 0">
                          共{{ item4.count }}题
                        </template>
                        <template #right-icon v-if="item4.count > 0">
                          <van-tag  color="#ee0a24" round plain size="medium" @click.stop="questionPractice(item4.id,3)">练习</van-tag>
                          <van-tag  color="#1989fa" round plain size="medium" @click.stop="questionDetail(item4.id,3)">查看</van-tag>
                        </template>
                      </van-cell>
                    </div>
                  </van-collapse-item>
                </van-collapse>


              </div>
              <!-- <van-empty image-size="30rem" description="暂未试题"/> -->
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div v-else>
        <van-empty
                description="暂无错题"
                image="http://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                image-size="24rem">
        </van-empty>
      </div>
    </div>
    <div @touchmove.stop>
      <van-popup class="showTest4" v-model:show="showTestView" position="bottom" closeable close-icon-position="top-left" close-icon="arrow-left" :style="{ height: '100%',width:'100%' }">
        <van-cell center title="试题详情" style="position: fixed;top: 0;text-align: center;z-index: 9;"></van-cell>
        <!-- 试题 -->
        <div class="result_testContent">
          <viewResolution :testPaperId="testPaperId" :url="url" :errorType="errorType" :key="timer" :questionLevel="questionLevel"></viewResolution>
        </div>
      </van-popup>
    </div>
    <pageLoading  v-show="showLoading"/>
  </div>
</template>

<script>
  import {questionErrorSubject, questionErrorKnowledgePointsList, testPaperKnowledgePointsList, questionError} from "@/request/api";
  import {Toast, Loading} from "vant";
  import {ImagePreview} from 'vant';
  import viewResolution from '@/components/viewResolution'
  import pageLoading from "@/components/pageLoading.vue";
export default {

  components: {pageLoading, ImagePreview, viewResolution},
  
  name: 'QuestionErrorSet',
  metaInfo: {
    title: '错题集', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      
      activeNames: ['1'],
      active: '',
      item: '',
      selectChapter: true,
      selectPaper: false,
      icon_exam: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_exam.png",
      icon_plus: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_plus.png",
      icon_minus: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_minus.png",
      icon_choice: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_choice.png",
      show:false,
      showTestView:false,
      showTestViewLx:false,
      url:'QuestionErrorSet',
      msg:'',
      testPaperId: '',
      // active:'',
      contentStyleObj: {
          height: '',
          width: ''
      },
      subjectList: [],
      subject_id: '',
      questionLevel:1,
      questionErrorList: [],
      timer: '',
      errorType:2,
      showLoading:true,
    }
  },
  created() {
    // 获取试题
    // this.getData()
    this.getQuestionErrorSubject();
  },

  mounted() {
      this.contentStyleObj.height = this.$refs.attrRef.offsetWidth * 9 / 16 + 'px ' + '!important';
      this.contentStyleObj.width = window.getComputedStyle(this.$refs.attrRef).width + ' ' + '!important';
  },

  methods: {
    
    onQuestionChapter() {
      if (this.selectChapter == true) {
        this.selectChapter = false
      } else if (this.selectChapter == false) {
        this.selectChapter = true
      }
    },

    toAskQuestion(){
        this.show = true
        if(this.show){
            console.log(this.show)
            setTimeout(()=>{
                this.show = false
            },2000)
        }
    },

    toPaper(){
      // this.$router.push('/PaperDetail')
      this.$router.push('/Zxks')
    },

    onBack() {
      history.back();
    },
    getQuestionErrorSubject() {
      let that = this;
      questionErrorSubject({})
              .then(res => {
                if (res.data.code == 1) {
                  if(res.data.data.data.length <= 0){
                    that.showLoading = false;
                  }
                  try {
                    that.subjectList = res.data.data.data;
                    res.data.data.data.forEach(function (item, index) {
                      that.subject_id = item.id;
                      that.getTestPaper();
                      throw Error();
                    })
                  }catch (e) {
                    console.log(e);
                  }

                }else{
                  that.showLoading = false;
                }
              })
              .catch(err => {
                // Toast.fail('请求错误1')
                that.showLoading = false;
                console.log("error");
                console.log(err);
              })
    },
    getTestPaper(pid = 0) {
      let that = this;
      let subjectId = that.subject_id;
      questionErrorKnowledgePointsList({subjectId,pid,level:1})
              .then(res => {
                that.showLoading = false;
                if (res.data.code == 1) {
                  that.questionErrorList = res.data.data.data;
                }
              })
              .catch(err => {
                that.showLoading = false;
                Toast.fail('请求错误')
                console.log("error");
                console.log(err);
              })
    },
    //加载下一级
    getChildTestPaper(level,id,key,key2) {
      let that = this;
      let subjectId = that.subject_id;
      if(key2 != undefined) {
        if(that.questionErrorList[key]['data'][key2]['data'] != undefined && that.questionErrorList[key]['data'][key2]['data'].length > 0) {
          let selectKey = that.questionErrorList[key]['data'][key2]['selectChapter'];
          that.questionErrorList[key]['data'][key2]['selectChapter'] = !selectKey;
          return false;
        }
      } else {
        if(that.questionErrorList[key]['data'] != undefined && that.questionErrorList[key]['data'].length > 0) {
          that.questionErrorList[key]['selectChapter'] = !that.questionErrorList[key]['selectChapter'];
          return false;
        }
      }
      questionErrorKnowledgePointsList({subjectId,pid:id,level})

              .then(res => {
                if (res.data.code == 1) {
                  if(key2 != undefined) {
                    that.questionErrorList[key]['data'][key2]['data'] = res.data.data.data;
                    that.activeNames.push(`${key}`+`${key2}`)
                    that.questionErrorList[key]['data'][key2]['selectChapter'] = !that.questionErrorList[key]['data'][key2]['selectChapter'];
                  } else {
                    that.questionErrorList[key]['data'] = res.data.data.data;
                    that.questionErrorList[key]['selectChapter'] = true;
                  }
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
    //关闭
    closedKey(key,key2) {
      if(key2 != undefined) {
        this.questionErrorList[key]['data'][key2]['selectChapter'] = !this.questionErrorList[key]['data'][key2]['selectChapter'];
      } else {
        this.questionErrorList[key]['selectChapter'] = !this.questionErrorList[key]['selectChapter'];
      }
    },
    questionDetail(id,level) {
      this.errorType = 2;
      console.log(1);
      this.timer = new Date().getTime()
      this.showTestView = false;
      this.testPaperId = id;
      this.questionLevel = level;
      this.showTestView = true;
    },
    //练习
    questionPractice(id,level) {
      this.errorType = 3;
      console.log(2);
      this.timer = new Date().getTime()
      this.showTestView = false;
      this.testPaperId = id;
      this.questionLevel = level;
      this.showTestView = true;

    },
    clickTab(name, title) {
      this.subject_id = name
      this.showLoading = true;
      this.getTestPaper();
    },
  },
}
</script>
 
<style lang = "less" >
.cart-title {
  z-index: 99;
}
  .testList .van-tabs__line {
        bottom: 1rem;
        width: 2.6rem;
        height: 0.5rem;
        border-radius: 1rem;
  }
  .testList .van-sticky--fixed {
        position: fixed;
        top: 6rem;
        right: 0;
        left: 0;
        z-index:  99;
  }
.question-exam {
  padding: 1.5rem 1rem !important;
}

.question-exam .left-icon01 {
  font-size: 4rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 0;
}

.question-exam .right-icon {
  font-size: 3rem !important;
  // color: #1989fa;
  padding: 0 1.3rem !important;
}

.question-exam .van-cell__title {
  padding-right: 1rem;
}

.question-exam .van-cell__label {
  line-height: 2.4rem !important;
  font-size: 1.8rem !important;
  padding-top: 1rem;
}

.question-child {
  margin-left: 3rem;
}

.question-child .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 2.3rem;
}

.question-progress {
  float: left;
  margin: 0.8rem 1rem 0 0 !important;
  display: flex;
  justify-content: center;
}

.question-progress .van-progress__pivot {
  display: none;
}

.question-exam-chapter {
  font-weight: 600 !important;
  font-size: 2.1rem;
}

.question-exam-collapse .van-collapse-item {
  padding: 0rem 1rem 0rem 3.5rem !important;
}

.question-exam-collapse .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 1.3rem;
}

.question-exam-collapse .van-collapse-item .van-collapse-item__title {
  align-items: center;
  padding: 1.5rem 0 !important;
}

.question-exam-collapse .van-collapse-item .van-collapse-item__title:active {
  background-color: #ffffff;
}

.question-exam-collapse .van-collapse-item .question-exam-list {
  // line-height: 6rem;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.question-exam-collapse .van-collapse-item .question-exam-list .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .right-icon {
  font-size: 3rem !important;
  padding: 1.5rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .question-exam-list .label {
  font-size: 1.8rem;
  color: #969799;
}

.question-exam-collapse .van-collapse-item .van-icon-arrow {
  line-height: 6rem;
  display: flex;
  align-items: center;
  font-weight: 600;
}


.question-exam-collapse::after {
  border-width: 0 !important;
}

.van-collapse-item__title::after {
  display: none !important;
}

.question-exam-collapse .van-collapse-item .question-exam-children {
  padding: 1.5rem 0 !important;
}

.question-exam-collapse .van-collapse-item .question-exam-children .left-icon {
  font-size: 2.6rem !important;
  padding: 1rem 1rem 1rem 0rem !important;
  background: #ffffff;
  z-index: 2;
}

.question-exam-collapse .van-collapse-item .question-exam-children .vertical-line {
  height: 100%;
  width: 0.1rem;
  background-color: #e7e9ed;
  position: absolute;
  left: 1.21rem;
}

.question-exam-collapse .van-collapse-item .question-exam-children .van-cell__label {
  line-height: 2.4rem !important;
  font-size: 1.8rem !important;
  padding-top: 1rem;
}

.question-exam-collapse .van-collapse-item .question-exam-children::after {
  border-width: 0 !important;
}


</style>
