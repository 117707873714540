<template>
    <div class="zxks_ksxx">
        <div class="zxks_ksxx_top" @click="closeFun">{{currentInfo.title}}</div>
        <!-- 考试信息 -->
        <div class="zxks_ksxx_mask" @click="closeFun">
            <div class="borderB">
                <div>
                    <img src="https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_zongfen.png" alt>
                </div>
                <div class="marginT2">
                    <div>试卷总分</div>
                    <div class="fontW">{{currentInfo.totalScore}}分</div>
                </div>
            </div>
            <div class="borderB borderL">
                <div>
                    <img src="https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_jige.png" alt>
                </div>
                <div class="marginT2">
                    <div>及格分</div>
                    <div class="fontW">{{currentInfo.passScore}}分</div>
                </div>
            </div>
            <div>
                <div>
                    <img src="https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_zongti.png" alt>
                </div>
                <div class="marginT2">
                    <div>总题数</div>
                    <div class="fontW">{{currentInfo.textNum}}题</div>
                </div>
            </div>
            <div class="borderL">
                <div>
                    <img src="https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_shijian.png" alt>
                </div>
                <div class="marginT2">
                    <div>考试时长</div>
                    <div class="fontW">{{currentInfo.time}}分钟</div>
                </div>
            </div>
        </div>
        <!-- 题型介绍 -->
        <div class="zxks_ksxx_time" @click="closeFun">
            <div class="textType"><img src="https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_kaoshishuoming.png"></img>题型介绍</div>
            <div v-for="(item,index) in currentInfo.textType" :key="index">
                <van-cell v-if="item.text_ty==2">
                    <template #title>
                        单项选择题：<span>每题1分，共{{item.textSub}}题，共{{item.text_score}}分</span>
                    </template>
                    <template #label>
                        <div style="margin-top:0.5rem">
                            下列每小题的五个选项中，只有一项是最符合题意的正确答案，多选、错选均不得分。
                        </div>
                    </template>
                </van-cell>
                <van-cell v-if="item.text_ty==6">
                    <template #title>
                        配伍选择题：<span>每题1分，共{{item.textSub}}题，共{{item.text_score}}分</span>
                    </template>
                    <template #label>
                        <div style="margin-top:0.5rem">
                            下列题目分为若干组，每组题目对应同一组备选项，备选项课重复使用，也可不选用。每题只有1个备选项最符合题意。
                        </div>
                    </template>
                </van-cell>
                <van-cell v-if="item.text_ty==5">
                    <template #title>
                        综合分析选择题：<span>每题1分，共{{item.textSub}}题，共{{item.text_score}}分</span>
                    </template>
                    <template #label>
                        <div style="margin-top:0.5rem">
                            下列题目分为若干组，每组题目基于同一个案例信息。每题只有1个备选项最符合题意。
                        </div>
                    </template>
                </van-cell>
                <van-cell v-if="item.text_ty==3">
                    <template #title>
                        多项选择题：<span>每题1分，共{{item.textSub}}题，共{{item.text_score}}分</span>
                    </template>
                    <template #label>
                        <div style="margin-top:0.5rem">
                            下列每小题的备选答案中，有两个或两个以上符合题意的正确答案，多选、少选、错选、不选均不得分。
                        </div>
                    </template>
                </van-cell>
            </div>

        </div>
        <!-- 考试说明 -->
        <!-- <div class="zxks_ksxx_kssm" @click="closeFun">
              <van-cell title="考试说明" >
              <template #icon>
                <img src="https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_kaoshishuoming.png" alt>
              </template>
              <template #label>
                <div>{{currentInfo.kssm}}</div>
          </template>
        </van-cell>
    </div> -->
        <div class="zxks_ksxx_btn">
            <!-- 类型   1:历年真题   2:模考试卷  3:海量题库  4:每日一练  6:随堂练习 7:名师好题 8:考前冲刺 9:白宝卷 -->
            <van-goods-action v-if="type != 2">
                <van-goods-action-button color="#3996f5" type="warning" text="练习模式" @click="datiFun(1)"/>
                <van-goods-action-button color="#1989fa" type="default" text="考试模式" @click="datiFun(2)"/>
            </van-goods-action>
            <van-goods-action v-else>
                <van-goods-action-button color="#3996f5" type="warning" text="练习模式" @click="datiFun(1)"/>
            </van-goods-action>
        </div>
    </div>
</template>

<script>
    //import * from '@/*/*'
    export default {
        components: {},
        props: ['currentInfo','type'],
        data() {
            return {}
        },
        watch: {},
        created() {
        },
        methods: {
            // 关闭考试信息的弹框
            closeFun() {
                this.$emit('closeFun')
            },
            // 开始答题
            datiFun(type) {
              console.log(this.currentInfo);
                // 练习模式
                if (type == 1) {
                    this.$router.push({
                        name: 'zxksDTLX',
                        query: {title: this.currentInfo.title, id: this.currentInfo.id, type: 1,commodity_id:this.currentInfo.commodity_id}
                    })
                }
                // 考试模式
                if (type == 2) {
                    this.$router.push({
                        name: 'zxksDT',
                        query: {time: this.currentInfo.time, id: this.currentInfo.id, type: 2,commodity_id:this.currentInfo.commodity_id}
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .zxks_ksxx {
        position: relative;
        overflow-y: auto;
        height: 100%;
    }

    .zxks_ksxx::-webkit-scrollbar {
        display: none;
    }

    .zxks_ksxx_top {
        width: 100%;
        position: absolute;
        top: 0;
        height: 16rem;
        font-size: 2.4rem;
        color: #fefffe;
        line-height: 3rem;
        padding-top: 3.5rem;
        text-align: center;
        color: #ffffff;
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/bg111.png');
        background-size: 100% 100%;
    }

    .zxks_ksxx_mask {
        /* width: 100%; */
        height: 16rem;
        margin: 12rem 2rem 1rem 2rem;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        box-shadow: 0 0 1rem rgb(211, 209, 209);
        border-radius: 1.5rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .zxks_ksxx_mask > div {
        width: 49%;
        display: flex;
        flex-wrap: wrap;
        height: 8rem;
    }

    .zxks_ksxx_mask > div > div {
        /* width: ; */
        padding: 1rem 0rem 1rem 1rem;
    }

    .zxks_ksxx_mask img {
        width: 6rem;
        height: 6rem;
        text-align: center;
    }

    .marginT2 {
        font-size: 1.8rem;
        line-height: 3rem;
        color: #323233;
        text-align: left;
    }

    .fontW {
        font-weight: bold;
        line-height: 3rem;
    }

    .borderL {
        border-left: 0.1rem solid #e7e9ed;
    }

    .borderR {
        border-right: 0.1rem solid #e7e9ed;
    }

    .borderT {
        border-top: 0.1rem solid #e7e9ed;
    }

    .borderB {
        border-bottom: 0.1rem solid #e7e9ed;
    }

    /* // 考试时间 */
    .zxks_ksxx_time {
        /* height: 100%; */
        margin: 0 2.5rem;
        position: relative;
        top: 29rem;
        background: #ffffff;
        padding-bottom: 8rem;
    }

    .textType {
        font-size: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0;
        float: left;
    }

    .textType img {
        width: 2.4rem;
        height: 2.4rem;
        padding: 0.5rem 0.5rem 0.5rem 0rem;
    }

    .zxks_ksxx_time .van-cell {
        width: 100%;
        padding-bottom: 1.5rem;
    }

    .zxks_ksxx_time .van-cell::after {
        border-width: 0 !important;
    }

    .zxks_ksxx_time .van-cell__label {
        line-height: 3rem;
    }

    .zxks_ksxx_time .van-cell__label > div {
        font-size: 1.8rem;
    }

    /* 考试说明 */
    .zxks_ksxx_kssm {
        height: auto;
        margin: 0 2.5rem;
        line-height: 3rem;
        position: relative;
        top: 29rem;
        background: #ffffff;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1rem;
    }

    .zxks_ksxx_kssm .van-cell {
        width: 100%;
    }

    .zxks_ksxx_kssm img {
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
    }

    .zxks_ksxx_kssm .van-cell__label {
        line-height: 3rem;
    }

    .zxks_ksxx_kssm .van-cell__label > div {
        font-size: 1.8rem;
        padding-bottom: 6rem;
    }

    /* .zxks_ksxx_kssm img {
      width: 2rem;
      height: 2rem;
    }
    .zxks_ksxx_kssm > div:nth-child(1) {
      width: 2rem;
      height: 2rem;
      padding: 0 0.5rem;
    }
    .zxks_ksxx_kssm > div:nth-child(2) {
      height: 2rem;
      line-height: 2rem;
      text-align: left;
    }
    .zxks_ksxx_kssm > div > div:nth-child(1) {
      text-align: left;
      font-size: 2rem;
      line-height: 2rem;
    }
    .zxks_ksxx_kssm > div > div:nth-child(2) {
      text-align: left;
      color: #999999;
      padding: 1rem 0 6rem 0;
      font-size: 1.8rem;
      line-height: 3rem;
    } */
    /* 按钮 */
    .zxks_ksxx_btn {
        /* padding: 0 2.5rem; */
        height: 6rem;
        padding: 1rem 0;
        position: fixed;
        bottom: 0rem;
        background-color: #ffffff;
        text-align: center;
        width: 100%;
        border-top: 0.1rem solid #e7e9ed;
    }

    .zxks_ksxx_btn /deep/ .van-button--info {
        padding: 2.5rem 10rem !important;
    }

    .van-goods-action {
        height: 8rem;
    }

    .zxks_ksxx_btn .van-goods-action-button--first {
        margin-left: 1rem !important;
    }

    .zxks_ksxx_btn .van-goods-action-button--last {
        margin-right: 1rem !important;
    }
</style>
