<template>
    <div>
        <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
            <template #title>
                问题详情
            </template>
        </van-nav-bar>
        <div class="questionDetail1">
            <div style="padding-top: 0.5rem;">
                <!-- 试题列表 -->
                <div class="testData">
                    <van-cell center class="answering">
                        <template #title>
                            <div class="testType">{{info.type==2 ?'单选题':(info.type==3 ? '多选题': (info.type==5 ?
                                '综合分析题':(info.type==6 ?'配伍选择题':'简答题')))}}
                            </div>
                            <!-- <span class="addtime">
                              <span @click="collection(currentTest.id,2)"><van-icon name="star" color="#ffd21e" size="2.2rem" style="margin:0 0.5rem;padding-right: 0rem;" /></span>
                              <span @click="collection(currentTest.id,1)"><van-icon name="star-o" color="#1989fa" size="2.2rem" style="margin:0 0.5rem;padding-right: 0.5rem;" /></span>
                              <span style="margin:0 0.5rem;color:#CCCCCC">|</span>
                              {{info.addtime}}
                            </span> -->
                            <div class="isStem" v-if="info.type==5 || info.type==6">
                                <span data-v-0b378ed5="" class="data_value" v-html="info.data_title"></span>
                                <van-image
                                        v-for="(item2,index) in info.data_title_img" :key="index"
                                        width="16rem"
                                        height="10rem"
                                        fit="fill"
                                        :src="item2"
                                        @click="getImg(info.title_img, index)"
                                />
                                <span v-for="(item3,index3) in info.data_title_video" :key="index3">
                                    <video
                                            id="myVideo"
                                            class="video-js"
                                            controls
                                            preload="auto"
                                            poster="https://video.ksyt.com.cn/8bec156084b771ed80e86732b78e0102/snapshots/73d4caec7dc34dcd9c88eb4716715f25-00005.jpg"
                                            :style="contentStyleObj"
                                    >
                                      <source :src="item3" type="video/mp4">
                                    </video>
                              </span>
                            </div>
                            <div class="custom-title" v-html="info.title"></div>
                            <van-image
                                    v-for="(item2,index) in info.title_img" :key="index"
                                    width="16rem"
                                    height="10rem"
                                    fit="fill"
                                    :src="item2"
                                    @click="getImg(info.title_img, index)"
                            />


                            <span v-for="(item3,index) in info.title_video" :key="index">
                                    <video
                                            id="myVideo"
                                            class="video-js"
                                            controls
                                            preload="auto"
                                            poster="https://video.ksyt.com.cn/8bec156084b771ed80e86732b78e0102/snapshots/73d4caec7dc34dcd9c88eb4716715f25-00005.jpg"
                                            :style="contentStyleObj"
                                    >
                                      <source :src="item3" type="video/mp4">
                                    </video>
                                  </span>
                        </template>
                        <template #label>
                            <div class="option" v-for="(item,index) in info.tmList" :key="index">
                                <p>
                                    <span class="option_key">{{item.key}}.</span>
                                    <span class="option_value">{{item.value}}
                                        <div v-if="item.tmImg.length > 0">
                                            <van-image v-for="(itemI2,indexI2) in item.tmImg" :key="indexI2" class="title_img" fit="fill" :src="itemI2"/>
                                      </div>
                                        <div v-if="item.tmVideo.length > 0">
                                            <video
                                                    v-for="(itemI2,indexI2) in item.tmVideo" :key="indexI2"
                                                    id="myVideo"
                                                    class="video-js"
                                                    controls
                                                    preload="auto"
                                                    poster="https://video.ksyt.com.cn/85ebf09087eb71ed801e6732b68f0102/snapshots/5973e73b3e27416fa7f90d87bc988f57-00005.jpg"
                                                    :style="contentStyleObj"
                                            >
                                                <source :src="itemI2" type="video/mp4">
                                            </video>
                                        </div>
                              </span>
                                </p>
                            </div>
                            <div data-v-0a1ab55d="" class="comRadio_answer">
                                <div data-v-0a1ab55d="" class="answer_box">
                                    <span data-v-0a1ab55d="" class="my_answer">参考答案：{{info.correct_answer}}</span>
<!--                                    <span data-v-0a1ab55d="" class="reference_answer_correct">我的答案：{{info.answer}}</span>-->
                                </div>
                                <div data-v-0a1ab55d="" class="knowledge_box">
                                    <span data-v-0a1ab55d="" class="knowledge">考察知识点：</span>
                                    <span data-v-0a1ab55d="" class="knowledge-text">{{info.knowledge}}</span>
                                </div>
                                <div data-v-0a1ab55d="" class="accuracy_box">
                                    <span data-v-0a1ab55d="" class="accuracy">全网正确率：</span>
                                    <span data-v-0a1ab55d="" class="accuracy-text">{{info.accuracy}}</span>
                                </div>
                            </div>

                            <div class="comRadio_video_analysis" v-if="info.videoAnalysis != ''">
                                <van-cell center title="讲师视频讲解" style="padding: 0">
                                </van-cell>
                                <div class="video_box" ref="attrRef">
                                    <video
                                            id="myVideo"
                                            class="video-js"
                                            controls
                                            preload="auto"
                                            :poster="info.videoAnalysisPoster"
                                            :style="contentStyleObj"
                                    >
                                        <source :src="info.videoAnalysis" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                            <div class="comRadio_analysis">
                                <van-cell center use-label-slot title="解析" style="padding: 0">
                                    <!-- <template #right-icon>
                                        <van-button round center type="warning" size="mini" icon="question-o" @click="onShowShare">我要提问</van-button>
                                    </template> -->
                                </van-cell>
                                <van-tag color="#f6f7f9" text-color="#646566" size="large">
                                    <!-- <span v-html="obj.analysis"></span> -->
                                    {{info.analysis}}
                                </van-tag>
                            </div>
                        </template>
                    </van-cell>
                </div>
                <div class="courseList1">
                    <van-cell center class="answering">
                        <template #icon>
                            <van-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"/>
                        </template>
                        <template #title>
                            <span class="custom-title">{{info.username}}</span><br>
                            <van-tag color="#f8f8f8" text-color="#969799">{{info.create_time ? info.create_time : '无'}}
                                - 来自{{info.source}}
                            </van-tag>
<!--                            <br>-->
                            <span></span><span v-html="info.questionTitle"></span><br>
                        </template>
                        <template #label>
                            <span>{{info.content}}</span>
                        </template>
                    </van-cell>
                </div>
                <van-divider/>
                <div class="courseList1" v-if="info.children != null">
                    <!-- <van-tag color="#1989fa" text-color="#ffffff" class="project_tag">中药学专业知识一</van-tag> -->
                    <van-cell center class="answering">
                        <template #icon>
                            <van-icon name="http://cdnoss.ksyt.com.cn/wxImages/item-askq.png"/>
                        </template>
                        <template #title>
                            <span class="custom-title">{{info.children ? info.children.real_name : '无'}}回复</span>
                            <van-tag color="#f8f8f8" text-color="#969799">{{info.children ? info.children.create_time :
                                '无'}}
                            </van-tag>
                        </template>
                        <template #label>
                            <span>{{info.children ? '亲爱的同学，很高兴能为您提供帮助，'+info.children.title : '无'}}</span>
                        </template>
                    </van-cell>
                </div>
                <!-- <van-empty image-size="30rem" description="暂无答疑问题"/> -->
            </div>


        </div>
    </div>
</template>

<script>

    import {ImagePreview} from 'vant';
    // import Tips from '@/components/Tips'
    let formName;
    import {getQuestionAnswerInfo} from '@/request/api'
    import {Toast} from "vant";

    export default {

        components: {ImagePreview},

        beforeRouteEnter(to, from, next) {
            let that = this;
            console.log(to);
            console.log(from);
            formName = from.name;
            // console.log(next);
            // if (!to.params || !to.params.id) {
            //     next({
            //         path: from.name,
            //         query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
            //     })
            // }
            next();
        },
        name: 'questionAnswerDetail',
        metaInfo: {
            title: '我的订单', //设置页面title
            meta: [{                 //设置页面meta
                name: 'keyWords',
                content: '关键词'
            },
                {
                    name: "description",
                    content: "描述",
                },
            ]
        },
        data() {
            return {
                // active:'',
                commodityId: '',
                courseId: '',
                courseWareId: '',
                id: '',
                info: [],

                contentStyleObj: {
                    height: '',
                    width: ''
                },
            }
        },

        mounted() {
            this.contentStyleObj.height = this.$refs.attrRef.offsetWidth * 9 / 16 + 'px ' + '!important';
            this.contentStyleObj.width = window.getComputedStyle(this.$refs.attrRef).width + ' ' + '!important';
        },
        methods: {

            getImg(images, index) {
                console.log(this.images)
                ImagePreview({
                    images: images, //需要预览的图片 URL 数组
                    showIndex: true, //是否显示页码
                    loop: false, //是否开启循环播放
                    startPosition: index //图片预览起始位置索引
                })
            },

            onBack() {
                console.log(formName);
                this.$router.push({
                    name: formName,
                })
            },
            getRepliedInfo() {
                let that = this;
                let id = that.id;
                getQuestionAnswerInfo({id}).then(res => {
                    if (res.data.status == 1) {
                        that.info = res.data.data;
                    }
                })
            },

        },
        created() {
            // 获取试题
            // this.getData();

            if (this.$route.params.commodityId) {
                this.commodityId = this.$route.params.commodityId;
            } else {
                this.commodityId = this.$route.query.commodityId;
            }
            if (this.$route.params.courseId) {
                this.courseId = this.$route.params.courseId;
            } else {
                this.courseId = this.$route.query.courseId;
            }
            if (this.$route.params.courseWareId) {
                this.courseWareId = this.$route.params.courseWareId;
            } else {
                this.courseWareId = this.$route.query.courseWareId;
            }
            if (this.$route.params.id) {
                this.id = this.$route.params.id;
            } else {
                this.id = this.$route.query.id;
            }
            this.getRepliedInfo();
        },
    }
</script>

<style lang="less">
    .questionDetail1 {
        margin: 6rem 1rem 1rem 1rem;
        padding: 1rem 0;
    }

    .questionDetail1 .courseList1 {
        border-radius: 1rem;
        background-color: #ffffff;
        // padding-bottom: 1.5rem;
    }

    .questionDetail1 .courseList1 .van-cell {
        border-radius: 1rem !important;
        padding: 1rem 1.5rem;
    }

    .questionDetail1 .courseList1 .van-cell::after {
        border-width: 0.5rem !important;
    }

    .questionDetail1 .courseList1 .answering .custom-title {
        font-size: 1.8rem;
        padding-right: 1rem;
        color: #646566;
    }

    .questionDetail1 .courseList1 .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }

    .questionDetail1 .courseList1 .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.4rem;
        padding-top: 0.5rem;
    }

    .questionDetail1 .courseList1 .project_tag {
        position: absolute;
        right: 1rem;
        float: right;
        padding: 0.3rem 0.5rem !important;
        font-size: 1.4rem !important;
        z-index: 99999;
        border-top-right-radius: 1rem;
    }

    .questionDetail1 .testData {
        margin: 0.5rem 0 1rem 0;
        background-color: #ffffff;
        padding-bottom: 1.5rem;
    }

    .questionDetail1 .testData .van-cell {
        border-radius: 1rem !important;
        padding: 1.5rem;
    }

    .questionDetail1 .testData .van-cell::after {
        border-width: 0 !important;
    }

    .questionDetail1 .testData .answering .custom-title {
        font-size: 1.8rem;
        // font-weight: 600;
        padding-right: 1rem;
        line-height: 3rem;
    }

    .questionDetail1 .testData .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }

    .questionDetail1 .testData .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.6rem;
    }

    .questionDetail1 .testData .answering .van-cell__label p {
        width: 100%;
        float: left;
        line-height: 3rem;
    }

    .questionDetail1 .testData .comRadio_answer {
        padding: 1rem 0;
        font-size: 1.8rem;
        // border-top: 0.4rem solid #f6f7f9;
    }

    .questionDetail1 .testData .comRadio_answer .my_answer {
        padding: 1rem 2.5rem 0.5rem 0rem;
        font-size: 1.8rem;
        color: #07c160;
    }

    .questionDetail1 .testData .comRadio_answer .reference_answer_correct {
        padding: 1rem 0rem 0.5rem 0rem;
        font-size: 1.8rem;
        color: #ee0a24;
    }

    .questionDetail1 .testData .comRadio_answer .knowledge_box .knowledge {
        padding: 0rem;
        font-size: 1.8rem;
        min-width: 12rem;
    }

    .questionDetail1 .testData .comRadio_answer .accuracy_box .accuracy {
        padding: 0rem;
        font-size: 1.8rem;
        min-width: 12rem;
    }

    .questionDetail1 .testData .comRadio_answer .accuracy_box .accuracy-text {
        padding: 0rem;
        font-size: 1.8rem;
    }

    .questionDetail1 .testData .comRadio_video_analysis {
        padding: 0;
        // border-top: 0.4rem solid #f6f7f9;
    }

    .questionDetail1 .testData .comRadio_video_analysis .van-cell__title {
        height: 1.8rem;
        line-height: 1.8rem;
        text-align: left;
        border-left: 0.5rem solid #1989fa;
        padding-left: 1rem;
        font-size: 1.8rem;
    }

    .questionDetail1 .testData .comRadio_analysis .van-cell__title {
        height: 1.8rem;
        line-height: 1.8rem;
        text-align: left;
        border-left: 0.5rem solid #1989fa;
        padding-left: 1rem;
        font-size: 1.8rem;
    }

    .questionDetail1 .testData .comRadio_analysis {
        padding: 0;
        // border-top: 0.4rem solid #f6f7f9;
    }

    .questionDetail1 .testData .comRadio_analysis .van-tag {
        font-size: 1.8rem !important;
        line-height: 2.4rem !important;
        border-radius: 0.5rem !important;
    }


    .questionDetail1 .testData .answering .testType {
        height: 2rem;
        line-height: 2rem;
        text-align: left;
        border-left: 0.5rem solid #1989fa;
        padding-left: 1rem;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .questionDetail1 .testData .answering .addtime {
        // float: right;
        font-size: 1.8rem;
        color: #1989fa;
        position: absolute;
        right: 1rem;
        top: 1.5rem;
    }

    .questionDetail1 .testData .answering .isStem .data_value {
        // float: right;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .questionDetail1 .testData .project_tag {
        position: absolute;
        left: 1rem;
        // float: left;
        padding: 0.3rem 0.8rem !important;
        font-size: 1.5rem !important;
        z-index: 99999;
        // border-top-left-radius: 1rem;
    }

    .questionDetail1 .testData .reply_tag {
        background-color: #f5f5f5;
        border-radius: 3rem;
        // padding-left: 0.3rem;
        height: 3.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // width: 90%;
        margin: 0 1rem;
    }

    .questionDetail1 .testData .reply_tag .van-tag {
        height: 2.8rem !important;
        // width: 3rem !important;
        border-radius: 3rem;
        padding: 0.1rem 1rem !important;
        font-size: 1.8rem !important;
    }

    .questionDetail1 .testData .reply_tag .reply_text {
        padding-left: 0.2rem;
        font-size: 1.8rem;
        color: #646566;
    }

</style>
